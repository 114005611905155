import React, { useMemo } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "components/layout"
import Container from "components/container"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { theme } from "common/colorScheme"
import { HeaderVariant } from "components/Header/types"
import { css } from "@emotion/react"

import Media from "components/Media"
import ArticlesGrid from "components/ArticlesGrid"
import SEO from "components/SEO"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const TopHeader = styled.header`
	width: 100%;
	background: linear-gradient(240deg, #662abc, #340e70);
	padding-top: calc(70px + ${theme.spacing(1)});
	padding-bottom: ${theme.spacing(8)};

	${mediaBreakpoint(BreakPoints.MD)} {
		padding-top: calc(70px + ${theme.spacing(5)});
	}
`

const topHeaderContainerStyles = css`
	display: flex;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
`

const headerContainerStyles = css`
	margin-top: ${theme.spacing(7.5)};
	margin-bottom: ${theme.spacing(7.5)};
`

const TopHeaderImageContainer = styled.div`
	width: 100%;
	height: 156px;
	border-radius: 50%;
	margin-right: ${theme.spacing(6.5)};
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	margin-bottom: ${theme.spacing(2.5)};

	${mediaBreakpoint(BreakPoints.MD)} {
		width: 235px;
		height: 235px;
	}
`

const TopHeaderTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const TopHeaderTextRole = styled.h4`
	font-size: 1.2em;
	color: ${theme.color.cyan};
	font-weight: 800;
`

const TopHeaderTextName = styled.h1`
	font-size: 2.4em;
	font-weight: 800;
	color: ${theme.color.white};

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 3.2em;
	}
`

const TopHeaderTextDescription = styled.p`
	font-size: 1.6em;
	line-height: 1.3em;
	color: ${theme.color.white};

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 2em;
	}

	a {
		color: ${theme.color.cyan};
		text-decoration: none;
	}
`

export const HeaderImage = styled.div`
	width: 100%;
	height: 400px;
	margin-top: ${theme.spacing(2)};
	margin-bottom: ${theme.spacing(5)};

	div {
		border-radius: 10px;
		overflow: hidden;
	}
`

export const Cover = styled.div`
	padding: 80px 0;
`

export const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
`

export const ArticleContent = styled.article`
	a {
		color: var(--text-color);
		text-decoration: underlline;
		font-weight: 700;
		&:hover {
			color: var(--main-color);
			text-decoration: underline;
		}
	}
	p {
		font-size: 2rem;
		line-height: 2.8rem;
		margin: 0 0 24px;
		&:empty {
			display: none;
		}
	}
	.twitter-tweet {
		opacity: 0;
	}
	.twitter-tweet.twitter-tweet-rendered {
		opacity: 1;
		transition: 0.2s opacity ease-in;
	}
	.instagram-container {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 50px 0;
	}
	ul,
	ol {
		font-size: 2rem;
		padding-left: 1rem;
		margin-left: 1rem;
	}
	li {
		margin: 0 0 1.2rem;
		line-height: 2.4rem;
	}
	h1 {
		font-size: 3.2rem;
		line-height: 3.8rem;
		font-weight: 800;
	}
	h2 {
		font-size: 2.4rem;
		line-height: 3rem;
		font-weight: 800;
	}
	h3 {
		font-size: 2rem;
		line-height: 2.8rem;
		font-weight: 800;
	}
	img {
		display: block;
		margin: 6.4rem auto;
	}
	iframe {
		max-width: 100%;
		width: 600px;
		margin: 6.4rem auto;
		display: block;
		border: none;
	}

	blockquote {
		opacity: 0.72;
		font-style: italic;
		> p:before {
			content: "\\201E";
		}
		> p:after {
			content: "\\201D";
		}
	}
`

const HEADER_CONFIG = {
	variant: HeaderVariant.TransparentWhite,
}

const Author = ({ data, pageContext }) => {
	const { contentfulAuthor, allContentfulBlogPost, contentfulForm,contentfulSeo } = data
	const { firstName, lastName, picture } = contentfulAuthor
	const { seo } = data.contentfulAuthor

	const richtext = pageContext.markdown
	const html = useMemo(() => {
		return documentToReactComponents(richtext)
	}, [richtext])

	const heading = `Articles by ${firstName}`

	return (
		<>
			{ seo ? <SEO {...seo} /> : <SEO {...contentfulSeo} /> }
			<Layout headerConfig={HEADER_CONFIG} footerConfig={{ variant: "default", hideDemo:false, hideNewsletter:false}}>
				<TopHeader>
					<Container css={topHeaderContainerStyles}>
						{picture && (
							<TopHeaderImageContainer>
								<Media {...picture} />
							</TopHeaderImageContainer>
						)}
						<TopHeaderTextContainer>
							<TopHeaderTextRole>AUTHOR</TopHeaderTextRole>
							<TopHeaderTextName>
								{firstName} {lastName}
							</TopHeaderTextName>
							{html && <TopHeaderTextDescription>{html}</TopHeaderTextDescription>}
						</TopHeaderTextContainer>
					</Container>
				</TopHeader>
				<Container css={headerContainerStyles}>
					<ArticlesGrid
						heading={heading}
						contentfulForm={contentfulForm}
						allContentfulBlogPost={allContentfulBlogPost}
					/>
				</Container>
			</Layout>
		</>
	)
}

export const query = graphql`
	query ($slug: String) {
		contentfulAuthor(slug: { eq: $slug }) {
			seo {
				...SEO
			}
			firstName
			lastName
			picture {
				...Media400
			}
			description {
				description
			}
		}
		allContentfulBlogPost(filter: { authorRef: { slug: { eq: $slug } }, node_locale: { eq: "en-US" } }) {
			edges {
				node {
					id
					slug
					title
					shortDescription
					publishDate
					heroImage {
						...Media450
					}
					tags
				}
			}
		}
		contentfulForm(formId: { eq: "newsletter" }) {
			...Form
		}
		contentfulSeo(title: { eq: "Brand Tracking Resources to Help Your Brand Grow" }) {
			...SEO
		}
	}
`

export default React.memo(Author)
